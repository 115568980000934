<template>
  <section class="pricing py-5" id="precos">
    <div class="container" data-aos="zoom-in" data-aos-duration="1000">
      <h2 class="text-center mt-0">Preços</h2>
      <hr class="divider" />
    </div>

    <div class="container" data-aos="zoom-in" data-aos-duration="1000">
      <div class="row">
        <!-- Plus Tier -->
        <div class="col-lg-4 d-flex align-items-stretch">
          <div class="card mb-lg-0">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title text-muted text-uppercase text-center">Básico</h5>
              <h6 class="card-price text-center">9,90<span class="period">/mês/funcionário</span></h6>
              <hr>
              <ul class="fa-ul">
                <li><span class="fa-li"><i class="fas fa-check"></i></span>até 5 funcionários ativos</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>máximo de 100 medições por mês</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>suporte por email</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>relatórios de despesas</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>fotos na nuvem</li>
                <li class="text-muted"><span class="fa-li"><i class="fas fa-times"></i></span>integração via API ou arquivos</li>                
              </ul>
            </div>
          </div>
        </div>
        <!-- Pro Tier -->
        <div class="col-lg-4 d-flex align-items-stretch">
          <div class="card border border-primary">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title text-muted text-uppercase text-center">Profissional</h5>
              <h6 class="card-price text-center">19,90<span class="period">/mês/funcionário</span></h6>
              <hr>
              <ul class="fa-ul">
                <li><span class="fa-li"><i class="fas fa-check"></i></span>até 50 funcionários ativos</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>sem limite de medições mensais</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>suporte por email</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>relatórios de despesas</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>fotos na nuvem</li>
                <li class="text-muted"><span class="fa-li"><i class="fas fa-times"></i></span>integração via API ou arquivos</li>                
              </ul>
              <!-- <div class="d-grid mt-auto">
                <router-link class="btn btn-primary text-uppercase" :to="{ name: 'registro', params: { plano: 'PRO' }}">Baixar</router-link>
              </div> -->
            </div>
          </div>
        </div>

        <!-- Enterprise Tier -->
        <div class="col-lg-4 d-flex align-items-stretch">
          <div class="card">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title text-muted text-uppercase text-center">Enterprise</h5>
              <h6 class="card-price text-center">29,90<span class="period">/mês/funcionário</span></h6>
              <hr>
              <ul class="fa-ul">
                <li><span class="fa-li"><i class="fas fa-check"></i></span>funcionários ilimitados</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>sem limite de despesas mensais</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>suporte premium</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>relatórios de despesas</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>fotos na nuvem</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>integração via API ou arquivos</li>
              </ul>
              <!-- <div class="d-grid mt-auto">
                <a href="#contato" class="btn btn-primary text-uppercase">Consulte</a>
              </div> -->
            </div>
          </div>
        </div>        
        <hr class="divider" />
        <h6 class="text-center mt-0">* os planos não possuem carência. Comece agora e cancele quando quiser.</h6>                
      </div>      
    </div>
  </section>
</template>

<script>
  export default {
    name: "Preco",
  };
</script>
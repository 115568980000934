<template>
  <section class="page-section bg-primary" id="sobre">
    <div class="container px-4 px-lg-5" data-aos="fade-right" data-aos-duration="2000">
      <div class="row gx-4 gx-lg-5 justify-content-center">
        <div class="col-lg-8 text-center">
          <h2 class="text-white mt-0">elimine o tempo gasto com planilhas</h2>
          <hr class="divider divider-light" />
          <p class="text-white mb-4">
            seu colaborador ou terceiro mede direto na obra
          </p>
          <p class="text-white mb-4">
            medição sem erro com foto anexa
          </p>
          <p class="text-white mb-4">
            projeto é atualizado automaticamente permitindo apuração para pagamento
          </p>
          <p class="text-white mb-4">
            relatório automático do projeto ou obra com possibilidade de envio para o cliente
          </p>
          <p class="text-white mb-4">
            relatório por projeto ou obra (centro de custo) com todo o histórico: medição, contestação, aprovação e pagamento
          </p>
          <p class="text-white mb-4">
            integre com seu ERP tenha a gestão dos projetos e obras de ponta a ponta
          </p>
          <a class="btn btn-light btn-xl" href="#comofunciona">Como funciona</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "Sobre",
  };
</script>
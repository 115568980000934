<template>
  <div>
    <Capa/>
    <Sobre/>
    <Comofunciona/>
    <Preco/>
    <Experimente/>
    <Contato/>
  </div>
</template>

<script>
  import Capa from './Capa.vue'
  import Sobre from './Sobre.vue'
  import Comofunciona from './Comofunciona.vue'
  import Preco from './Preco.vue'
  import Experimente from './Experimente.vue'
  import Contato from './Contato.vue'

  export default {
    name: "Home",
    components: {
      Capa,
      Sobre,
      Comofunciona,
      Preco,
      Experimente,
      Contato
    }
  };
</script>
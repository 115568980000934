<template>
  <!-- Contact-->
  <section class="page-section" id="contato">
    <div class="container px-4 px-lg-5" data-aos="zoom-out" data-aos-duration="2000">
      <div class="row gx-4 gx-lg-5 justify-content-center">
        <div class="col-lg-8 col-xl-6 text-center">
          <h2 class="mt-0">Vamos conversar?</h2>
          <hr class="divider" />
          <p class="text-muted mb-5">Pronto para iniciar a gestão de sua casa de repouso conosco? Mande-nos uma mensagem que entraremos em
            contato para tirarmos todas as suas dúvidas.</p>
        </div>
      </div>
      <div class="row gx-4 gx-lg-5 justify-content-center mb-5">
        <div class="col-lg-6">
          <form @submit="checkForm" id="contactForm">
            <!-- Name input-->
            <div class="form-floating mb-3">
              <input class="form-control" id="name" type="text" v-model="nome"/>
              <label for="name">Nome completo</label>
            </div>
            <!-- Email address input-->
            <div class="form-floating mb-3">
              <input class="form-control" id="email" type="email" v-model="email"/>
              <label for="email">Email</label>
            </div>
            <!-- Phone number input-->
            <div class="form-floating mb-3">
              <input class="form-control" id="phone" type="tel" v-model="telefone"/>
              <label for="phone">Telefone (opcional)</label>
            </div>
            <!-- Message input-->
            <div class="form-floating mb-3">
              <textarea class="form-control" id="message" type="text" v-model="mensagem"
                style="height: 10rem"></textarea>
              <label for="message">Mensagem</label>
            </div>
            <p v-if="ok">
              <b>Sua mensagem foi enviada. Entraremos em contato em breve.</b>
            </p>
            <p v-if="errors.length > 0">
              <b>Por favor, preencha:</b>
              <ul class="fa-ul">
                <li v-for="error in errors"  v-bind:key="error">{{ error }}</li>                    
              </ul>
            </p>
            <!-- Submit Button-->
            <div class="d-grid"><button class="btn btn-primary btn-xl" id="submitButton"
                type="submit">Enviar</button></div>
          </form>
        </div>
      </div>
      <div class="row gx-4 gx-lg-5 justify-content-center">
        <div class="col-lg-4 text-center mb-5 mb-lg-0">
          <i class="bi-envelope fs-2 mb-3 text-muted"></i>
          <div>
            você também pode nos enviar um email: <a class="navbar-brand"
              href="mailto:contato@rt1.com.br">contato@gestorcr.com.br</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "Contato",
    data: function () {
      return {
        errors: [],
        ok: false,
        nome: '',
        email: '',
        telefone: '',
        mensagem: ''
      }
    },
    methods: {
      checkForm: function (e) {
        this.ok = false;
        e.preventDefault();

        this.errors = [];
        console.log(this.nome);

        if (this.nome === '') 
          this.errors.push('Nome completo');
        
        if (this.email === '') 
          this.errors.push('Email');
        else if (!this.validEmail(this.email)) 
          this.errors.push('Email inválido.');

        if (this.mensagem === '') 
          this.errors.push('Mensagem');

        if (this.errors.length > 0) 
          return;
        
        let msg_body = JSON.stringify(
          {
            nome: this.nome,
            email: this.email,
            telefone: this.telefone,
            mensagem: this.mensagem
          }
        );

        fetch(this.$baseUrl + 'empresa/mensagem', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: msg_body
        }).then(async res => {
            if (res.status === 200) {
              this.ok = true;
            } else if (res.status === 400) {
              let errorResponse = await res.json();
              this.errors.push(errorResponse.error);
            }
        });
      },
      validEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }      
    }
  };
</script>
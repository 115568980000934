<template>
  <div id="app">
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
      <div class="container px-4 px-lg-5">
        <img src="./assets/img/logo_app.png" class="img-fluid img-logo" alt="logo">
        <!-- <a class="navbar-brand" href="#about">GestorCR</a> -->
        <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
          aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ms-auto my-2 my-lg-0">
            <li v-if="$route.path != '/'" class="nav-item">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <li v-if="$route.path == '/'" class="nav-item">
              <a class="nav-link" href="#about">Sobre</a>
            </li>
            <li v-if="$route.path == '/'" class="nav-item">
              <a class="nav-link" href="#comofunciona">Como funciona</a>
            </li>
            <li v-if="$route.path == '/'" class="nav-item">
              <a class="nav-link" href="#precos">Preços</a>
            </li>
            <li v-if="$route.path == '/'" class="nav-item">
              <a class="nav-link" href="#contato">Contato</a>
            </li>
            <li><a class="btn btn-primary btn-download" href="#experimente">começar</a></li>            
          </ul>          
        </div>
      </div>
    </nav>

    <router-view />

    <!-- Footer-->
    <footer class="bg-light py-5">
      <div class="container px-4 px-lg-5">
        <div class="small text-center text-muted">Todos os direitos reservados &copy; 2022 - GestorCR
          <!-- <a class="nav-link" target="_blank" href="https://gestorcr.com.br"></a> -->
        </div>
      </div>
    </footer>
  </div>
</template>

<style>@import 'css/styles.css';</style>
<style>@import 'css/aos.css';</style>

<script>
  export default {
    name: 'App'
  }
</script>
<template>
  <header class="masthead">
    <div class="container px-4 px-lg-5 h-100" data-aos="fade-down" data-aos-duration="3000">
      <div class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
        <div class="col-lg-8 align-self-end">
          <!-- <h1 class="text-dark font-weight-bold">Apontamento e aprovação de despesas</h1> -->
          <h1 class="text-dark font-weight-bold">Sistema de Gestão para</h1>
          <h1 class="text-dark font-weight-bold">Casas de Repouso</h1>
          <hr class="divider" />
        </div>
        <div class="col-lg-8 align-self-baseline">
          <p class="text-dark mb-5">ficha completa do hóspede, controle de medicamentos, atividades, 
            alimentação, lavanderia e financeiro. Tudo que sua casa de repouso precisa.
          </p>
          <a class="btn btn-primary btn-xl" href="#sobre">Saiba mais</a>
          <!-- <a class="btn btn-primary btn-xl" href="#precos">Quero começar agora!</a> -->
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  export default {
    name: "Registrado",    
  };
</script>
<template>
  <section class="page-section bg-dark text-white" id="experimente">
    <div class="container px-4 px-lg-5 text-center" data-aos="flip-left" data-aos-duration="1000">
       <div class="row">
        <div class="col-sm">
          <h2 class="mb-4">Quer experimentar?</h2>
          <a class="btn btn-light btn-xl" target="_blank" href="#">Teste grátis por 30 dias</a>
        </div>
        <div class="col-sm">
          <a target="_blank" href="#">
            <img src="../../assets/img/google-play-badge.png" class="img-fluid" alt="googleplay">
          </a>
        </div>
      </div>
      <!-- <h2 class="mb-4">Quer experimentar?</h2>
      <a class="btn btn-light btn-xl" target="_blank" href="#">Teste grátis por 30 dias</a> -->
    </div>
  </section>
</template>

<script>
  export default {
    name: "Registrado"    
  };
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import AOS from './js/aos.js'
import './js/scripts.js'
import Home from './components/home/Home.vue'
import Liberar from './components/Liberar.vue'

AOS.init()
Vue.config.productionTip = false;
Vue.prototype.$debug = false;

if (Vue.prototype.$debug) 
  Vue.prototype.$baseUrl = 'http://localhost:8000/';
else
  Vue.prototype.$baseUrl = 'https://apiteste.gestorcr.com.br/';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {path: '/', name: 'home', component: Home, meta: { liberado: false }},
    {path: '/liberar', name: 'liberar', component: Liberar},
    {path: "*", component: Home}
  ],
  scrollBehavior() {
    document.getElementById('page-top').scrollIntoView();
  }
})

router.beforeEach(async (to, from, next) => {
  if (!router.options.routes[0].meta.liberado && to.name !== 'liberar') {
    next({path: '/liberar'});
  } else {    
    next();
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
<template>
  <section class="page-section" id="comofunciona">
    <div class="container px-4 px-lg-5" data-aos="zoom-in" data-aos-duration="1000">
      <h2 class="text-center mt-0">da Obra ao Administrativo</h2>
      <hr class="divider" />
      <div class="row gx-4 gx-lg-5">
        <div class="col-lg-3 col-md-6 text-center">
          <div class="mt-5">
            <div class="mb-2"><i class="bi-rulers fs-1 text-primary"></i></div>
            <h3 class="h4 mb-2">Medição</h3>
            <p class="text-muted mb-0">tire uma foto do serviço e lance a medição no celular</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 text-center">
          <div class="mt-5">
            <div class="mb-2"><i class="bi-check fs-1 text-primary"></i></div>
            <h3 class="h4 mb-2">Aprovação</h3>
            <p class="text-muted mb-0">o gestor do projeto ou obra contesta ou aprova o serviço</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 text-center">
          <div class="mt-5">
            <div class="mb-2"><i class="bi-graph-up fs-1 text-primary"></i></div>
            <h3 class="h4 mb-2">Gestão</h3>
            <p class="text-muted mb-0">acompanhamento do projeto ou obra atualizado automaticamente</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 text-center">
          <div class="mt-5">
            <div class="mb-2"><i class="bi-heart fs-1 text-primary"></i></div>
            <h3 class="h4 mb-2">Entrega!</h3>
            <p class="text-muted mb-0">seu projeto ou obra entregue com mais confiabilidade e acompanhamento do cliente</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 text-center">
          <div class="mt-5">
            <div class="mb-2"><i class="bi-gear fs-1 text-primary"></i></div>
            <h3 class="h4 mb-2">Integre</h3>
            <p class="text-muted mb-0">informações de medição no seu ERP através de API ou arquivo</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 text-center">
          <div class="mt-5">
            <div class="mb-2"><i class="bi-book fs-1 text-primary"></i></div>
            <h3 class="h4 mb-2">Aprenda</h3>
            <p class="text-muted mb-0">na nossa plataforma de aulas on-line e extraia o máximo do sistema</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 text-center">
          <a class="nav-link" target="_blank" href="https://rt1.com.br">
            <div class="mt-5">
              <div class="mb-2"><i class="bi-shield-check fs-1 text-primary"></i></div>
              <h3 class="h4 mb-2">Precisa de Mais?</h3>
              <p class="text-muted mb-0">conheça o nosso <b>ERP</b> para gestão completa da empresa,
                já integrado com o easyMedição</p>
            </div>
          </a>
        </div>        
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "Comofunciona",    
  };
</script>
<template>
  <div>
    <header class="mastheadliberado">
      <div class="container p-5 px-lg-5 bg-light" data-aos="zoom-out">
        <br><br><br><br>
        <h3 class="text-center mt-0">Lançamento em breve!</h3>
        <br>
        <div class="row gx-4 gx-lg-5 justify-content-center mb-5">
          <div class="col-lg-6">
            <form @submit="checkForm" id="liberarForm">
              <!-- Name input-->
              <div class="form-floating mb-3">
                <input
                  class="form-control"
                  id="name"
                  type="password"
                  autocomplete="off"
                  v-model="senha"
                />
                <label for="name">senha</label>
              </div>

              <!-- Submit Button-->
              <div class="d-grid">
                <button class="btn btn-primary btn-xl" id="submitButton" type="submit">
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Liberado",
  data: function () {
    return {
      senha: "" //"q1w2e3"
    };
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();

      if (this.senha === "q1w2e3") {
        this.$router.options.routes[0].meta.liberado = true;
        console.log(this.$router.options.routes[0].meta);
        this.$router.push({path: '/'});
        return '';
      }
    }    
  },
};
</script>